<template>
  <div class="container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between">
        <div class="text-h4">IP Access</div>
      </div>
      <div class="row q-gutter-xl">
        <q-btn @click="showModal = true" color="primary">Add IP Address</q-btn>
      </div>

      <q-dialog v-model="showModal" @hide="ipAddress = ''; ipLabel = ''">
        <q-card style="width: 560px; max-height: 1000px;" class="bg-lmsBackground primaryText-text">
          <q-form @submit="addIp" @reset="showModal = false" autocomplete="off">
            <q-card-section>
              <div class="text-h5">Create New IP Address</div>
            </q-card-section>
            <q-card-section>
              <div class="col-2 q-pa-sm">
                <q-input label="IP Address" placeholder="127.0.0.1" type="text" v-model="ipAddress" :rules="formRules.ip">
                  <template v-slot:after>
                    <q-btn outline color="primary" @click="ipAddress = $store.state.auth.user.ip_address">Add This Computer</q-btn>
                  </template>
                </q-input>
              </div>
              <div class="col-2 q-pa-sm">
                <q-input label="Name" placeholder="Storefront" type="text" v-model="ipLabel" :rules="formRules.name" />
              </div>
            </q-card-section>
            <div class="row gutter-xs q-my-lg q-mx-lg">
              <div class="col-12">
                <q-btn type="submit" class="float-right" :loading="saving" color="primary">Add</q-btn>
                <q-btn type="reset" flat class="float-right on-left">Cancel</q-btn>
              </div>
            </div>
          </q-form>
        </q-card>
      </q-dialog>

      <div class="full-width">
        <div class="col-12 q-pt-none" v-if="ipList.length > 0">
          <h6 class="custom-header-color tab-text-150 q-mb-sm">Assigned IP Addresses</h6>
          <q-list class="q-pa-none" highlight separator no-border v-for="(address, index) in ipList" :key="address.uid">
            <q-item class="q-pa-md">
              <q-item-section class="col-1">
                <q-toggle v-model="address.settings.enforce" color="positive" @input="toggleIp(address)" />
              </q-item-section>
              <q-item-section>
                 <q-item-label>{{address.ip}}</q-item-label>
                 <q-item-label caption>{{address.settings.label}}</q-item-label>
              </q-item-section>
              <q-item-section side class="primaryText-text">
                <q-btn flat round icon="delete" @click="deleteIp(index, address.uid)" :loading="(deleting === address.uid)" />
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
 </div>
</template>

<script>
export default {
  data () {
    return {
      ipAddress: '',
      ipLabel: '',
      ipList: [],
      showModal: false,
      saving: false,
      deleting: null
    }
  },
  computed: {
    formRules: function () {
      return {
        ip: [ this.$formRules.required('IP Address') ],
        name: [ this.$formRules.required('Name') ]
      }
    }
  },
  watch: {
    'showModal' (val) {
      if (!val) {
        this.saving = false
        this.ipAddress = ''
        this.ipLabel = ''
      }
    }
  },
  methods: {
    addIp () {
      this.saving = true
      this.$axios.post(this.$consts.ADD_IP_ACCESS_URL, {
        ip: this.ipAddress,
        settings: JSON.stringify({
          label: this.ipLabel,
          enforce: true
        })
      }).then((response) => {
        this.ipList.push(response.data)
        this.showModal = false
        this.$successMsg()
      }).catch((error) => {
        this.saving = false
        this.$failureMsg()
        throw error
      })
    },
    deleteIp (index, uid) {
      this.deleting = uid
      this.$axios.delete(this.$consts.DELETE_IP_ACCESS_URL + '/' + uid).then((response) => {
        this.ipList.splice(index, 1)
        this.$successMsg()
        this.deleting = null
      }).catch((error) => {
        this.deleting = null
        this.$failureMsg()
        throw error
      })
    },
    toggleIp (address) {
      this.$axios.post(this.$consts.UPDATE_IP_ACCESS_URL + address.uid, {
        ip: address.ip,
        settings: JSON.stringify(address.settings)
      }).then((response) => {
        this.$successMsg()
      })
    }
  },
  created () {
    this.$q.loading.show('loading...')
    this.$axios.get(this.$consts.GET_IP_ACCESS_URL).then((response) => {
      this.ipList = response.data
      this.$q.loading.hide()
    }).catch((error) => {
      throw error
    })
  }
}
</script>
